import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   name: 'Index',
  //   redirect: '/home'
  // },
  {
    path: '/',
    name: 'Home',
    redirect: '/index',
    component: () => import('@/views/home/index'),
    children: [
      {
        path: 'index',
        name: 'Home Index',
        component: () => import('@/views/home/index/index')
      },
      {
        path: 'about',
        name: 'Home About',
        component: () => import('@/views/home/about/index')
      },
      {
        path: 'contact',
        name: 'Home Contact',
        component: () => import('@/views/home/contact/index')
      }
    ]
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('@/views/download/index')
  },
  {
    path: '/weekly',
    name: 'Weekly',
    component: () => import('@/views/weekly/index')
  },
  {
    path: '/news/article',
    name: 'News Article',
    component: () => import('@/views/news/article')
  },
  {
    path: '/news/video',
    name: 'News Video',
    component: () => import('@/views/news/video')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
