import axios from 'axios'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

const get = (url, data) => service({
  url,
  method: 'get',
  params: data
})

const post = (url, data) => service({
  url,
  method: 'post',
  header: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  data
})

const put = (url, data) => service({
  url,
  method: 'put',
  data
})

const DELETE = (url, data) => service({
  url,
  method: 'delete',
  data
})

export default {
  namespaced: true,
  get,
  post,
  put,
  delete: DELETE
}
